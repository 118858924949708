<template>
  <form-dialog
    append-to-body
    :name="$t('deals.form.title')"
    :id="cpId"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    @submit="saveReservation"
    :saving="saving"
    :autofocus="false"
  >
    <ul id="deal-form" class="form-field-rows">
      <form-row v-if="!currentClientId && !clientIds && !cpId" :title="$t('deals.form.contact')">
        <client-select v-model="clientId" :include-children="false" />
      </form-row>

      <form-row v-if="!currentClientId && clientIds && !cpId" :title="$t('deals.form.contacts')">
        <p>
          <strong>{{ clientIds.length }}</strong>
          {{ $t("deals.form.selectedContacts") }}
        </p>
      </form-row>

      <form-row :title="$t('deals.form.property')">
        <property-select v-model="propertyId" @change="projectId = null" :disabled="!!projectId" />
      </form-row>

      <form-row v-if="!currentPropertyId && !cpId" :title="$t('deals.form.project')">
        <project-select :model-value="projectId" @update:model-value="selectProject" :disabled="!!propertyId" />
      </form-row>

      <form-row v-if="pipelines.length > 1 || !dealPipelineId" :title="$t('deals.form.pipeline')">
        <nice-select
          :options="pipelines"
          v-model="dealPipelineId"
          @change="resetDialogDealStages"
          :placeholder="$t('deals.form.pipelinePh')"
        />
      </form-row>

      <form-row :class="{ disabled: !dealPipelineId }" :title="$t('deals.form.stage')">
        <div class="w-100">
          <nice-select
            filterable
            clearable
            v-model="dealStageId"
            :placeholder="$t('deals.form.stagePh')"
            data-testid="deal-stage-select"
            :options="dealPhasesForActivePipeline"
          >
            <template #option="{ item }">
              <nice-label :label="item.name" :color="item.color" />
            </template>
          </nice-select>

          <div v-if="dealPipelineId && dealStageId == wonStatusId" class="mt-2">
            <nice-checkbox v-model="deleteReservations">
              {{ $t("deals.form.unqualify") }}
            </nice-checkbox>
          </div>
        </div>
      </form-row>

      <form-row :class="{ disabled: !dealPipelineId }" :title="$t('deals.form.user')">
        <nice-select
          filterable
          clearable
          v-model="brokerId"
          :placeholder="$t('deals.form.userPh')"
          :options="brokersForActivePipeline"
          label-key="internalName"
        ></nice-select>
      </form-row>

      <form-row :title="$t('deals.form.date')">
        <nice-date-picker fixed-position v-model="date" />
      </form-row>

      <form-row :title="$t('deals.form.price')">
        <currency-input v-model="soldPrice" placeholder="1.000.000" />
      </form-row>

      <form-row :title="$t('deals.form.source')">
        <db-select v-model="source" collection="clientSources" />
      </form-row>

      <form-row :title="$t('deals.form.team')">
        <db-select v-model="teamId" collection="teams" />
      </form-row>

      <form-row :title="$t('deals.form.departments')">
        <db-select multiple v-model="departmentIds" collection="departments" />
      </form-row>

      <form-row :title="$t('deals.form.feeling')">
        <div class="w-100">
          <nice-slider v-model="feeling" :max="100" />
        </div>
      </form-row>

      <form-row :title="$t('deals.form.note')">
        <nice-textarea v-model="note" :placeholder="$t('deals.form.notePh')" :min-rows="2" />
      </form-row>
    </ul>

    <logs-list v-if="cpId" resource-type="ClientProperty" :resource-id="cpId" :brokers="$parent.brokers" />
  </form-dialog>
</template>

<script>
import LogsList from "../LogsList"

export default {
  props: ["visible", "cp", "currentClientId", "currentPropertyId", "clientIds"],
  emits: ["update:visible", "save"],
  data({ cp }) {
    return {
      saving: false,
      deleteReservations: false,
      cpId: cp.id,
      dealPipelineId: cp.dealPipelineId,
      clientId: cp.clientId,
      propertyId: cp.propertyId,
      projectId: cp.projectId,
      brokerId: cp.brokerId,
      dealStageId: cp.dealStageId,
      source: cp.source,
      date: cp.date,
      soldPrice: cp.soldPrice,
      note: cp.note,
      feeling: cp.feeling,
      teamId: cp.teamId,
      departmentIds: cp.departmentIds,
    }
  },

  watch: {
    visible(visible) {
      if (visible) {
        const cp = this.cp
        this.cpId = cp.id
        this.dealPipelineId = cp.dealPipelineId
        this.clientId = cp.clientId
        this.propertyId = cp.propertyId
        this.projectId = cp.projectId
        this.brokerId = cp.brokerId
        this.dealStageId = cp.dealStageId
        this.source = cp.source
        this.date = cp.date
        this.soldPrice = cp.soldPrice
        this.note = cp.note
        this.feeling = cp.feeling
        this.teamId = cp.teamId
        this.departmentIds = cp.departmentIds
      }
    },
  },

  methods: {
    selectProject(event) {
      this.projectId = event
      this.propertyId = null
    },
    resetDialogDealStages() {
      const stages = this.dealPhasesForActivePipeline.filter(o => !o.disabled)
      this.dealStageId = stages.length > 0 ? stages[0].id : undefined
      if (!this.brokersForActivePipeline.find(o => o.id == this.brokerId)) this.brokerId = null
    },

    saveReservation() {
      this.saving = true
      if (this.clientIds) {
        this.$axios
          .post("/services/mass_edit/create_client_property", {
            client_ids: this.clientIds,
            client_property: {
              property_id: this.propertyId,
              project_id: this.projectId,
              broker_id: this.brokerId,
              deal_stage_id: this.dealStageId,
              client_source_id: this.source,
              date: this.date,
              price: this.soldPrice,
              note: this.note,
              feeling: this.feeling,
              team_id: this.teamId,
              department_ids: this.departmentIds,
              // delete_reservations: this.deleteReservations,
              // for_property: !!this.projectId, // für units view, damit andere response zurückgegeben wird
            },
          })
          .then(({ data }) => {
            this.saving = false
            App.flashy(this.$t("deals.form.success"))
            this.$emit("update:visible", false)
            this.$emit("save", data)
          })
          .catch(err => {
            this.saving = false
            this.$axios.handleError(err)
          })
      } else {
        this.$axios
          .post("/services/reservations", {
            client_id: this.clientId,
            property_id: this.propertyId,
            project_id: this.projectId,
            client_property_id: this.cpId,
            broker_id: this.brokerId,
            deal_stage_id: this.dealStageId,
            client_source_id: this.source,
            date: this.date,
            price: this.soldPrice,
            note: this.note,
            feeling: this.feeling,
            delete_reservations: this.deleteReservations,
            team_id: this.teamId,
            department_ids: this.departmentIds,
            for_property: !!this.projectId, // für units view, damit andere response zurückgegeben wird
          })
          .then(({ data }) => {
            this.saving = false
            App.flashy(this.$t("deals.form.successAlt"))
            this.$emit("update:visible", false)
            this.$emit("save", data)
          })
          .catch(err => {
            this.saving = false
            this.$axios.handleError(err)
          })
      }
    },
  },

  computed: {
    pipelines() {
      return this.$db.shopData.dealPipelines
    },

    dealPhasesForActivePipeline() {
      return this.$db
        .get("dealStages")
        .map(stage => ({
          ...stage,
          disabled: !(
            stage.dealPipelineId === this.dealPipelineId &&
            (!stage.excludedBrokerIds || !stage.excludedBrokerIds.includes(this.$db.broker.id))
          ),
        }))
        .filter(o => !o.disabled)
    },

    activePipeline() {
      return this.pipelines.find(o => o.id == this.dealPipelineId)
    },
    departmentBrokerIds() {
      return this.$db.shopData.departments.reduce((agg, cur) => {
        agg[cur.id] = cur.brokerIds
        return agg
      }, {})
    },
    brokersForActivePipeline() {
      if (!this.activePipeline) return []
      const activePipelineBrokerIds = new Set([
        ...this.activePipeline.brokerIds,
        ...this.activePipeline.departmentIds.flatMap(id => this.departmentBrokerIds[id] ?? []),
      ])
      return this.$db.shopData.brokers.filter(broker => broker.admin || activePipelineBrokerIds.has(broker.id))
    },

    wonStatusId() {
      const found = this.dealPhasesForActivePipeline.find(o => o.chance === 1)
      if (found) {
        return found.id
      }
    },
  },

  components: {
    LogsList,
  },
}
</script>
