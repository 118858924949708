import countries from "i18n-iso-countries"
import parsePhoneNumber, { CountryCode } from "libphonenumber-js"

const getCCFromCountryName = countryName => {
  var result: {
    alpha2: string | null
    alpha3: string | null
  } = null
  countries.langs().forEach(lang => {
    const alpha2 = countries.getAlpha2Code(countryName, lang)
    const alpha3 = countries.getAlpha3Code(countryName, lang)
    if (alpha2 != undefined && alpha3 != undefined) {
      result = {
        alpha2,
        alpha3,
      }
    }
  })
  return result
}

export default (phoneNumber: string, clientCountry, shopCountry) => {
  const { alpha2, alpha3 } = getCCFromCountryName(clientCountry) ||
    getCCFromCountryName(shopCountry) || { alpha2: "DE", alpha3: "DEU" }
  const parsedPrefixedPhoneNumber = parsePhoneNumber(phoneNumber, alpha2 as CountryCode)
  var nationalNumber = parsedPrefixedPhoneNumber?.nationalNumber.toString()
  if (nationalNumber?.startsWith("0")) nationalNumber = nationalNumber.substring(1, nationalNumber.length)
  if (phoneNumber.startsWith("0")) {
    return `${alpha3CodeToDialPrefix[alpha3!]}${nationalNumber}`
  }
  return `${parsedPrefixedPhoneNumber?.countryCallingCode}${nationalNumber}`
}

const alpha3CodeToDialPrefix = {
  AFG: "93",
  ALA: "358",
  ALB: "355",
  DZA: "213",
  ASM: "1-684",
  AND: "376",
  AGO: "244",
  AIA: "1-264",
  ATA: "672",
  ATG: "1-268",
  ARG: "54",
  ARM: "374",
  ABW: "297",
  AUS: "61",
  AUT: "43",
  AZE: "994",
  BHS: "1-242",
  BHR: "973",
  BGD: "880",
  BRB: "1-246",
  BLR: "375",
  BEL: "32",
  BLZ: "501",
  BEN: "229",
  BMU: "1-441",
  BTN: "975",
  BOL: "591",
  BES: "599",
  BIH: "387",
  BWA: "267",
  BVT: "47",
  BRA: "55",
  IOT: "246",
  VGB: "1-284",
  BRN: "673",
  BGR: "359",
  BFA: "226",
  BDI: "257",
  CPV: "238",
  KHM: "855",
  CMR: "237",
  CAN: "1",
  CYM: "1-345",
  CAF: "236",
  TCD: "235",
  CHL: "56",
  CHN: "86",
  HKG: "852",
  MAC: "853",
  CXR: "61",
  CCK: "61",
  COL: "57",
  COM: "269",
  COG: "242",
  COK: "682",
  CRI: "506",
  HRV: "385",
  CUB: "53",
  CUW: "599",
  CYP: "357",
  CZE: "420",
  PRK: "850",
  COD: "243",
  DNK: "45",
  DJI: "253",
  DMA: "1-767",
  DOM: "1-809",
  ECU: "593",
  EGY: "20",
  SLV: "503",
  GNQ: "240",
  ERI: "291",
  EST: "372",
  SWZ: "268",
  ETH: "251",
  FLK: "500",
  FRO: "298",
  FJI: "679",
  FIN: "358",
  FRA: "33",
  GUF: "594",
  PYF: "689",
  ATF: "262",
  GAB: "241",
  GMB: "220",
  GEO: "995",
  DEU: "49",
  GHA: "233",
  GIB: "350",
  GRC: "30",
  GRL: "299",
  GRD: "1-473",
  GLP: "590",
  GUM: "1-671",
  GTM: "502",
  GGY: "44",
  GIN: "224",
  GNB: "245",
  GUY: "592",
  HTI: "509",
  HMD: "672",
  VAT: "39-06",
  HND: "504",
  HUN: "36",
  ISL: "354",
  IND: "91",
  IDN: "62",
  IRN: "98",
  IRQ: "964",
  IRL: "353",
  IMN: "44",
  ISR: "972",
  ITA: "39",
  CIV: "225",
  JAM: "1-876",
  JPN: "81",
  JEY: "44",
  JOR: "962",
  KAZ: "7",
  KEN: "254",
  KIR: "686",
  KWT: "965",
  KGZ: "996",
  LAO: "856",
  LVA: "371",
  LBN: "961",
  LSO: "266",
  LBR: "231",
  LBY: "218",
  LIE: "423",
  LTU: "370",
  LUX: "352",
  MDG: "261",
  MWI: "265",
  MYS: "60",
  MDV: "960",
  MLI: "223",
  MLT: "356",
  MHL: "692",
  MTQ: "596",
  MRT: "222",
  MUS: "230",
  MYT: "262",
  MEX: "52",
  FSM: "691",
  MCO: "377",
  MNG: "976",
  MNE: "382",
  MSR: "1-664",
  MAR: "212",
  MOZ: "258",
  MMR: "95",
  NAM: "264",
  NRU: "674",
  NPL: "977",
  NLD: "31",

  NCL: "687",
  NZL: "64",
  NIC: "505",
  NER: "227",
  NGA: "234",
  NIU: "683",
  NFK: "672",
  MNP: "1-670",
  MKD: "389",
  NOR: "47",
  OMN: "968",
  PAK: "92",
  PLW: "680",
  PAN: "507",
  PNG: "675",
  PRY: "595",
  PER: "51",
  PHL: "63",
  PCN: "870",
  POL: "48",
  PRT: "351",
  PRI: "1",
  QAT: "974",
  KOR: "82",
  MDA: "373",
  REU: "262",
  ROU: "40",
  RUS: "7",
  RWA: "250",
  BLM: "590",
  SHN: "290",
  KNA: "1-869",
  LCA: "1-758",
  MAF: "590",
  SPM: "508",
  VCT: "1-784",
  WSM: "685",
  SMR: "378",
  STP: "239",
  SAU: "966",
  SEN: "221",
  SRB: "381 p",
  SYC: "248",
  SLE: "232",
  SGP: "65",
  SXM: "1-721",
  SVK: "421",
  SVN: "386",
  SLB: "677",
  SOM: "252",
  ZAF: "27",
  SGS: "500",
  SSD: "211",
  ESP: "34",
  LKA: "94",
  PSE: "970",
  SDN: "249",
  SUR: "597",
  SJM: "47",
  SWE: "46",
  CHE: "41",
  SYR: "963",
  TWN: "886",
  TJK: "992",
  THA: "66",
  TLS: "670",
  TGO: "228",
  TKL: "690",
  TON: "676",
  TTO: "1-868",
  TUN: "216",
  TUR: "90",
  TKM: "993",
  TCA: "1-649",
  TUV: "688",
  UGA: "256",
  UKR: "380",
  ARE: "971",
  GBR: "44",
  TZA: "255",
  USA: "1",
  VIR: "1-340",
  URY: "598",
  UZB: "998",
  VUT: "678",
  VEN: "58",
  VNM: "84",
  WLF: "681",
  ESH: "212",
  YEM: "967",
  ZMB: "260",
  ZWE: "263",
}
