import db from "@/config/db"
import i18n from "../i18n"
import phoneNumberFormatter from "./phone-number-formatter"

const { t } = i18n.global

export const detectAndOpenWhatsAppWeb = async (clientPhone, clientCountry, text?) => {
  const phone = phoneNumberFormatter(clientPhone, clientCountry, db.shopData.internationalCountry)
  const uri = `whatsapp://send/?phone=${encodeURIComponent(phone)}&text=${encodeURIComponent(text)}`
  const webUri = `https://web.whatsapp.com/send?phone=+${encodeURIComponent(phone)}?text=${encodeURIComponent(text)}`

  const onIE = () => {
    return new Promise(resolve => {
      window.navigator.msLaunchUri(
        uri,
        () => resolve(true),
        () => resolve(false)
      )
    })
  }

  const notOnIE = () => {
    return new Promise(resolve => {
      const a = document.getElementById("wapp-launcher") || document.createElement("a")
      a.id = "wapp-launcher"
      a.href = uri
      a.style.display = "none"
      document.body.appendChild(a)

      const start = Date.now()
      const timeoutToken = setTimeout(() => {
        if (Date.now() - start > 1250) {
          resolve(true)
        } else {
          resolve(false)
        }
      }, 1000)

      const handleBlur = () => {
        clearTimeout(timeoutToken)
        resolve(true)
      }
      window.addEventListener("blur", handleBlur)

      a.click()
    })
  }

  const promise = window.navigator.msLaunchUri ? onIE() : notOnIE()
  if (!(await promise)) {
    const newWindow = window.open(webUri, "_blank")
    console.log("webUri", webUri)
    console.log("newWindow", newWindow)
    if (!newWindow || newWindow.closed || typeof newWindow.closed == "undefined") {
      alert(t("general.popupBlocked"))
    }
  }
}
